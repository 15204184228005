const columns = [
  {
    label: "日期",
    prop: "date",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "达人昵称(英文)",
    prop: "creator_handle",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "达人昵称(原文)",
    prop: "creator_name",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "直播间名称",
    prop: "live_name",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "直播间ID",
    prop: "live_id",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "开播时间范围",
    prop: ["start_time", "end_time"],
    align: "center",
    width: 180,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "开播时长(h)",
    prop: "duration",
    align: "center",
    width: 100,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "成交金额($)",
    prop: "amount_delimited",
    align: "center",
    width: 100,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "累计观看人数",
    prop: "total_views_unique_cnt",
    align: "center",
    width: 110,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "累计成交件数",
    prop: "item_sold_cnt",
    align: "center",
    width: 110,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "直播订单数",
    prop: "paid_order_cnt",
    align: "center",
    width: 100,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
];
export default columns;
